<div [class.pt-0]="isDocsPage()" class="d-flex flex-direction-column wrapper">
  <app-header [isDocsPage]="isDocsPage()" />

  <router-outlet />
  <div class="m-auto"></div>
  @if (!isDocsPage()) {
    <app-footer />
  }
  <app-cookie-banner />
  <app-intensive-timer />
</div>
